import AppEntryLayout from "../../components/AppEntryLayout";
import StepWizard from "../../components/Login/StepWizard";
import SlackAuthLogin from "../../components/Login/SlackAuthLogin";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { AUTH_DATA, CACHE_MAXAGE, CACHE_REVALIDATE, FEATURE_MAP, USER_ID } from "../../const";
import { defaultStore, useStore } from "../../hooks/useStore";
import Redirecting from "../../components/Redirecting";
import { useTranslation } from "react-i18next";
import { Box } from "../../components/SharedComponents";
import { stdLogout } from "../../utils/frontend/utils";
import { localStore } from "../../utils/frontend/localStore";
import { globalWarningNotification } from "../../components/SharedComponents/notification";

const APP = process.env.NEXT_PUBLIC_APP;

export default function Login({ cookies, consumedInvitation, slackApp, force }) {
  const [store, setStore] = useStore();
  const router = useRouter();
  const { t } = useTranslation();
  const [destinationPageName, setDestinationPageName] = useState("");

  useEffect(() => {
    consumedInvitation && globalWarningNotification(`Invitation link has already been used`);
    if (force === "true") {
      void router.push("/login");
      void stdLogout().then(() => {
        setStore(defaultStore);
      });
    }

    // Only run once on page load. DO NOT add deps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (router.query.trigger === "logout") {
      void stdLogout().then(() => {
        setStore(defaultStore);
        void router.replace("/login", undefined, { shallow: true });
      });
      return;
    }

    if (store?.userInfo?.authData) {
      const userID = localStore.get(USER_ID);
      const authData = localStore.get(AUTH_DATA);
      if (!userID || !authData) {
        void stdLogout().then(() => {
          setStore(defaultStore);
        });
        return;
      }
      const paramShowLogin = router.query.showLogin;
      if (paramShowLogin === "true") {
        return;
      }
      const path = router.asPath.slice(router.asPath.indexOf("#") + 1);
      const enabledFeatures = store.userInfo?.userConfig?.enabledFeatures ?? [];
      if (enabledFeatures.includes(FEATURE_MAP?.audit?.id)) {
        setDestinationPageName("Audits page");
        !slackApp &&
          router.push(
            path.includes("/login") || path.includes("/risk-manager") ? "/audits/projects" : path
          );
        console.log("path: ", path);
      }
      // otherwise
      else {
        setDestinationPageName("Account page");
        !slackApp && router.push("/account");
      }
      setStore((prev) => ({
        ...prev,
        loggedIn: true,
      }));
    }
  }, [router, slackApp, store?.userInfo]);

  return !store.loggedIn ? (
    <AppEntryLayout title={t("loginForm.login")}>
      <Box width={450} ml="auto" mr="auto" mt="48px">
        {<StepWizard cookies={cookies} isSlackApp={slackApp} />}
      </Box>
    </AppEntryLayout>
  ) : slackApp ? (
    <AppEntryLayout title={t("loginForm.login")}>
      <Box width={450} ml="auto" mr="auto" mt="48px">
        <SlackAuthLogin />
      </Box>
    </AppEntryLayout>
  ) : (
    <Redirecting toPage={destinationPageName} />
  );
}

export function getServerSideProps(context) {
  context.res.setHeader(
    "Cache-Control",
    `public,max-age=0, s-maxage=${CACHE_MAXAGE}, stale-while-revalidate=${CACHE_REVALIDATE}`
  );

  const { consumedInvitation, slackApp, force } = context.query;
  return {
    props: {
      cookies: context.req.cookies,
      consumedInvitation: consumedInvitation != undefined,
      slackApp: slackApp != undefined,
      force: force || null,
    }, // will be passed to the page component as props
  };
}
