import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "../../hooks/useStore";
import { APP_TYPE } from "../../const";
import { slackAuthWithLoginAccess } from "../../utils/frontend/fetchFromApi";
import { Alert, RedirectCardWithChannelPicker, Flex } from "../SharedComponents";
import { PageSpinner } from "../Widgets/spinner";

const CardDiv = styled.div`
  margin: 80px auto;
  width: 464px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

export default function SlackAuthLogin() {
  const [store] = useStore();
  const [status, setStatus] = useState("start");

  useEffect(() => {
    async function run() {
      setStatus("loading");
      const resp = await slackAuthWithLoginAccess(store.userInfo?.userId, store.userInfo?.authData);
      if (!resp || !resp.success) {
        setStatus("error");
      } else {
        setStatus("success");
      }
    }

    // only run it once at the start
    status === "start" &&
      store?.userInfo?.userId &&
      store?.userInfo?.authData &&
      run().catch((err) => {
        setStatus("error");
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.userInfo]);

  return status === "loading" || status === "start" ? (
    <PageSpinner spinning />
  ) : status === "error" ? (
    <Flex margin="100px auto">
      <Alert
        level="error"
        title="Slack App authorization failed, please try again"
        iconVisible
        style={{ alignSelf: "center" }}
      />
    </Flex>
  ) : (
    <CardDiv>
      <RedirectCardWithChannelPicker
        redirectRoute={"/organization?tab=3&orgUpdated=true"}
        platform={APP_TYPE.SLACK}
      />
    </CardDiv>
  );
}
