import { Spin, Space } from "antd";
import { Title } from "../SharedComponents";
import styled from "styled-components";

const RedirectingContainer = styled(Space)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 45%;
  left: 50%;
  padding: 48px;
  padding-bottom: 32px;
  background-color: var(--red-50);
  border: 1px solid var(--red-200);
  border-radius: 10px;
  text-align: center;
`;

export default function Redirecting({ toPage }) {
  return (
    <RedirectingContainer direction="vertical" size={32}>
      <Spin
        size="large"
        indicator={<img src="/page_loader.gif" style={{ width: "96px", height: "96px" }} />}
      />
      {toPage && <Title level={3}>Redirecting to {toPage}</Title>}
    </RedirectingContainer>
  );
}
