import { useState } from "react";
import LoginForm from "./LoginForm";
import TfaForm from "./TfaForm";
import { useTranslation } from "react-i18next";
import { Title, Space } from "../SharedComponents";

export default function StepWizard({ cookies, isSlackApp }) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [userInfo, setUserInfo] = useState({});

  const onNext = () => setStep((prev) => prev + 1);

  const title = step === 0 ? t("loginForm.login") : t("loginForm.verifyThatsYou");

  return (
    <Space direction="vertical" size={8} $fillWidth={true}>
      <Title level={1} color="gray-800" weight={500} size={24}>
        {title}
      </Title>
      {isSlackApp && (
        <Title level={2} color="gray-800" weight={400} size={14}>
          Please login to SkyHarbor to complete the integration process of the SkyHarborBot app for
          Slack
        </Title>
      )}
      {step === 0 && (
        <LoginForm
          setUserInfo={setUserInfo}
          onNext={onNext}
          cookies={cookies}
          isSlackApp={isSlackApp}
        />
      )}
      {step === 1 && <TfaForm userInfo={userInfo} isSlackApp={isSlackApp} />}
    </Space>
  );
}
